import { Component } from "react";
import ReactHtmlParser from 'react-html-parser';
import MyAccountMenu from "../components/myAccountMenu.component";

import { getCookieValue } from "../utils/general.utils";

class MySearches extends Component {
    constructor(){
        super();

        this.state = {
            page_name: "searches",
            pageLoaded: false,
            showBtn: true,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            showForm: true,
            formData: {
                search_term1: "",
                search_term2: "",
                search_term3: "",
                search_term4: "",
                search_term5: ""
            }
        }
    }

    async componentDidMount() {
        try {          
            const username = getCookieValue('cct_username');
            const user_id = getCookieValue('cct_user_id');    

            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/searches.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
              request_type: "get_search_terms",
              username: username,
              user_id: user_id
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            
            if (ajaxResponse.length > 0) {
                const { search_term_1, search_term_2, search_term_3, search_term_4, search_term_5 } = ajaxResponse[0];
                
                this.setState({
                    pageLoaded: true,
                    formData: {
                        search_term1: search_term_1,
                        search_term2: search_term_2,
                        search_term3: search_term_3,
                        search_term4: search_term_4,
                        search_term5: search_term_5
                    }
                })
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleSubmit = async(e) => {
        e.preventDefault();

        const{search_term1, search_term2, search_term3, search_term4, search_term5} = this.state.formData;

        this.setState({
            showBtn: false,
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        });

        const username = getCookieValue('cct_username');
        const user_id = getCookieValue('cct_user_id');

        try {                
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/searches.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                username: username,
                user_id: user_id,
                search_term1: search_term1,
                search_term2: search_term2,
                search_term3: search_term3,
                search_term4: search_term4,
                search_term5: search_term5,
                request_type: "process_search_terms"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, search_update_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    this.setState({
                        showBtn: true,
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: errorMsg,
                        successMsg: "",
                    });
                } else {
                    if(successMsg !== "" && search_update_status === "updated"){
                        this.setState({
                            displayError: false,
                            displaySuccess: true,
                            errorMsg: "",
                            successMsg: successMsg,
                            showBtn: true
                        });                     
                    } else {
                        this.setState({
                            showBtn: true,
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            showForm: true
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleChange = (e) => {
        const { name, value, type } = e.target;
        const fieldValue = value;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: fieldValue
            }
        }));
    }


    render(){
        document.title = "Real-time Filings Searches - Conference Call Transcripts";

        const cct_username = getCookieValue('cct_username');
        const cct_password = getCookieValue('cct_password');

        const{ page_name, showBtn, displayError, errorMsg, displaySuccess, successMsg, pageLoaded } = this.state;
        const{ handleChange, handleSubmit } = this;

        if(cct_username === "" && cct_password === ""){
            const redirect_url = `${window.location.origin}/my-account`;
            window.location.href = redirect_url;
        } else {
            return(
                <div id="page-content" className="mt-1 mb-5">
                <div className="container">

                <MyAccountMenu page_name={page_name} />

                <div className="row">
                <div className="col-lg-12">
                <h4>Search Filings in Real-time</h4>
                </div>
                </div>

                <div className="row">
                <div className="col-lg-12">
                <p><b>Be the first to know when a preset phrase is released in an OTC or SEDAR filing! You will receive an e-mail when a term is found.<br />Cases are insensitive (e.g. Sales = sales).</b></p>
                <p><b>Edit your search terms here:</b></p>
                </div>
                </div>

                <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
                <div className="col-lg-12">
                <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>
                </div>

                <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>

                <div className="row mb-2">
                <div className="col-lg-12">
                <div className="alert alert-success mt-3 mb-0" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>

                <div className="alert alert-danger mt-3 mb-0" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>
                </div>
                </div>

                <form onSubmit={handleSubmit}>
                <div className="row align-items-center mb-3">
                <div className="col-auto pe-0 me-0 w-0"><h4 className="mb-0 pb-0">1)</h4></div>
                <div className="col-sm-4 col-7"><input type="text" placeholder="e.g. turnaround" className="form-control" id="search_term1" name="search_term1" onChange={handleChange} value={this.state.formData.search_term1 !== null ? this.state.formData.search_term1 || '' : ''} /></div>
                <div className="col-sm-7 col-4"></div>
                </div>

                <div className="row align-items-center mb-3">
                <div className="col-auto pe-0 me-0 w-0"><h4 className="mb-0 pb-0">2)</h4></div>
                <div className="col-sm-4 col-7"><input type="text" placeholder="e.g. sales have doubled" className="form-control" id="search_term2" name="search_term2" onChange={handleChange} value={this.state.formData.search_term2 !== null ? this.state.formData.search_term2 || '' : ''} /></div>
                <div className="col-sm-7 col-4"></div>
                </div>

                <div className="row align-items-center mb-3">
                <div className="col-auto pe-0 me-0 w-0"><h4 className="mb-0 pb-0">3)</h4></div>
                <div className="col-sm-4 col-7"><input type="text" placeholder="e.g. shares are undervalued" className="form-control" id="search_term3" name="search_term3" onChange={handleChange} value={this.state.formData.search_term3 !== null ? this.state.formData.search_term3 || '' : ''} /></div>
                <div className="col-sm-7 col-4"></div>
                </div>

                <div className="row align-items-center mb-3">
                <div className="col-auto pe-0 me-0 w-0"><h4 className="mb-0 pb-0">4)</h4></div>
                <div className="col-sm-4 col-7"><input type="text" placeholder="e.g. private placement" className="form-control" id="search_term4" name="search_term4" onChange={handleChange} value={this.state.formData.search_term4 !== null ? this.state.formData.search_term4 || '' : ''} /></div>
                <div className="col-sm-7 col-4"></div>
                </div>

                <div className="row align-items-center mb-3">
                <div className="col-auto pe-0 me-0 w-0"><h4 className="mb-0 pb-0">5)</h4></div>
                <div className="col-sm-4 col-7"><input type="text" placeholder="e.g. outlook is very positive" className="form-control" id="search_term5" name="search_term5" onChange={handleChange} value={this.state.formData.search_term5 !== null ? this.state.formData.search_term5 || '' : ''} /></div>
                <div className="col-sm-7 col-4"></div>
                </div>

                <div className="form-group mt-3">
                <div style={showBtn ? {display: 'none'} : {display: 'block'}}>
                <div className="d-flex" >
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>

                <button type="submit" className="theme-btn-1 btn btn-effect-1 mt-3" style={showBtn ? {display: 'block'} : {display: 'none'}}>Try It FREE!</button>
                </div>

                </form>
                
                
                
                </div>

                </div>
                </div>
            )
        }
    }
}

export default MySearches