import { Component } from "react";
import ReactHtmlParser from 'react-html-parser';

class ForgotPassword extends Component{
    constructor(){
        super();

        this.state = {
            showBtn: true,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            showForm: true,
            formData: {
                username: "",
                email: ""
            }
        }
    }

    handleSubmit = async(e) => {
        e.preventDefault();

        const{username, email} = this.state.formData;

        this.setState({
            showBtn: false,
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        });

        try {                
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/forgot_password.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                username: username,
                email: email
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, pwd_reset_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    this.setState({
                        showBtn: true,
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: errorMsg,
                        successMsg: "",
                        showForm: true
                    });
                } else {
                    if(successMsg !== "" && pwd_reset_status === "reset"){
                        this.setState({
                            displayError: false,
                            displaySuccess: true,
                            errorMsg: "",
                            successMsg: successMsg,
                            showForm: false
                        });                     
                    } else {
                        this.setState({
                            showBtn: true,
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            showForm: true
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleChange = (e) => {
        const { name, value, type } = e.target;
        const fieldValue = value;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: fieldValue
            }
        }));
    }

    render(){
        document.title = "Forgot Password - Conference Call Transcipts";

        const { showBtn, displayError, errorMsg, displaySuccess, successMsg, showForm } = this.state;
        const { handleChange, handleSubmit } = this;


        return(
            <div id="page-content" className="mt-1 mb-5">
            <div className="container">

            <div className="row">
            <div className="col-lg-12">
            <h1 className="entry-title">Forgot Password</h1>
            </div>
            </div>

            <div className="row">
            <div className="col-lg-12">
            <h4 className="text-center">Please provide the Username and E-mail Address combination you used to create your account.</h4>
            </div>
            </div>

            <div className="row mt-3">
                <div className="col-md-4 offset-md-4 col-12">

                <div className="alert alert-success mt-3 mb-0" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>

                <div className="alert alert-danger mt-3 mb-0" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>

                <div className="mt-2" style={showForm ? {display: 'block'} : {display: 'none'}}>
                <form onSubmit={handleSubmit}>
                
                <div className="row">
                <div className="col-lg-12">
                <div className="form-group">
                <label htmlFor="username"><b>Username</b></label>
                <input type="text" className="form-control" id="username" name="username"  onChange={handleChange} value={this.state.formData.username} />
                </div>
                </div>
                </div>

                <div className="row mt-2">
                <div className="col-lg-12">
                <div className="form-group">
                <label htmlFor="email"><b>E-mail Address</b></label>
                <input type="text" className="form-control" id="email" name="email" onChange={handleChange} value={this.state.formData.email} />
                </div>
                </div>
                </div>

                <div className="form-group mt-3">
                <div style={showBtn ? {display: 'none'} : {display: 'block'}}>
                <div className="d-flex" >
                <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
                </div>
                </div>
                </div>

                <button type="submit" className="theme-btn-1 btn btn-effect-1 mt-3" style={showBtn ? {display: 'block'} : {display: 'none'}}>Sign Up</button>
                </div>

                </form>
                </div>

                </div>
                </div>

            </div>
            </div>
        );
    }
}

export default ForgotPassword;