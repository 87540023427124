import { Component } from "react";
import MyAccountMenu from "../components/myAccountMenu.component";
import { removeCookie } from "../utils/general.utils";

class Logout extends Component{
    constructor(){
        super();

        this.state = {
            pageLoaded: false,
            page_name: "logout"
        }
    }

    componentDidMount(){
        this.userLogOut();
    }

    userLogOut = () => {
        removeCookie('cct_username');
        removeCookie('cct_password');
        removeCookie('cct_user_email');
        removeCookie('cct_user_id');

        const redirect_url = window.location.origin;
        window.location.href = redirect_url;
    }

    render(){
        document.title = "Conference Call Transcripts";

        const{ page_name, pageLoaded } = this.state;

        return(
            <div id="page-content" className="mt-1 mb-5">
            <div className="container">

            <MyAccountMenu page_name={page_name} />

            <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            </div>
            </div>
        )
    }
}

export default Logout;