import { Component, Fragment } from "react";
import AddNotificationsBtn from "./addNotificationsBtn.component";
import CompanyFinancials from "./companyFinancials.component";
import CompanyFilings from "./companyFilings.component";
import CompanyReleases from "./companyReleases.component";
import CompanyTranscripts from "./companyTranscripts.component";
import { getCookieValue } from "../utils/general.utils";

class CompanyMain extends Component{
    constructor(props){
        super(props);

        this.state = {
            symbol: props.symbol,
            companyDetails: [],
            data: [],
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            pageLoaded: false,
            companyId: "",
            companyTicker: "",
            companyCountry: "",
            following: "",
            showTab: "transcripts"
        }
    }

    async componentDidMount() {
        try {   
            const cct_user_id = getCookieValue("cct_user_id");
            const ticker = this.state.symbol;               
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/get_company.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                symbol: this.state.symbol,
                user_id: cct_user_id
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            this.setState({ companyDetails: ajaxResponse });
            this.setState({pageLoaded: true})

            if (ajaxResponse.length > 0) {
                const { id, ticker, name, country, following } = ajaxResponse[0];
                document.title = `${name} (${ticker}) - Conference Call Transcripts`;

                this.setState({
                    companyId: id,
                    companyTicker: ticker,
                    companyCountry: country,
                    following: following
                });
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    handleToggleTab = (e, tab_type) => {
        e.preventDefault();
        this.setState({ showTab: tab_type });
    }

    render(){
        const{symbol, companyDetails, pageLoaded, companyId, companyTicker, companyCountry, showTab, following} = this.state;
        const{handleToggleTab} = this;

        //console.log({companyCountry});

        return(
            <div id="page-content" className="mt-1 mb-5">
            <div className="container">
            
            <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
            {companyDetails.length !== 0 ? (
                companyDetails.map((cd) => {
                    let id = cd.id;
                    let ticker = cd.ticker;
                    let company_name = cd.name;
                    let company_country = cd.country;
                    let latest_price = cd.price;
                    let latest_change = cd.price_change;
                    let change_color = "#0B1FB2";
                    if(parseFloat(latest_change) > 0){
                        latest_change = "+" + latest_change;
                        change_color = "#0a7b44";
                    } else {
                        change_color = "#c9392c";
                    }

                    if(latest_price === null || latest_price === undefined){
                        latest_price = "";
                        latest_change = "";
                    }

                    return(
                        <Fragment key="company_details">
                        <div className="row mb-3 align-items-center">
                        <div className="col-lg-8 col-md-8">
                        <h3>{company_name} ({ticker}) <span className="ms-3" style={{ color: change_color, display: latest_price !== "" ? 'inline-block' : 'none' }}>{latest_price} ({latest_change})</span></h3>
                        </div>

                        <div className="col-lg-4 col-md-4 d-flex justify-content-md-end">
                        <AddNotificationsBtn companyId={companyId} companyTicker={companyTicker} companyCountry={companyCountry} following={following} />
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-lg-12">
                        
                        <nav>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <button className="nav-link active" id="nav-transcripts-tab" data-bs-toggle="tab" data-bs-target="#nav-transcripts" type="button" role="tab" aria-controls="nav-transcripts" aria-selected="true" onClick={(e) => handleToggleTab(e, "transcripts")}>Transcripts</button>
                            <button className="nav-link" id="nav-filings-tab" data-bs-toggle="tab" data-bs-target="#nav-filings" type="button" role="tab" aria-controls="nav-filings" aria-selected="false" onClick={(e) => handleToggleTab(e, "filings")}>Filings</button>
                            <button className="nav-link" id="nav-financials-tab" data-bs-toggle="tab" data-bs-target="#nav-financials" type="button" role="tab" aria-controls="nav-financials" aria-selected="false" onClick={(e) => handleToggleTab(e, "financials")}>Financials</button>
                            <button className="nav-link" id="nav-releases-tab" data-bs-toggle="tab" data-bs-target="#nav-releases" type="button" role="tab" aria-controls="nav-releases" aria-selected="false" onClick={(e) => handleToggleTab(e, "releases")}>Releases</button>
                        </div>
                        </nav>
                        <div className="tab-content" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-transcripts" role="tabpanel" aria-labelledby="nav-transcripts-tab">
                        {showTab === "transcripts" ? (
                            <CompanyTranscripts companyId={companyId} companyTicker={companyTicker} companyCountry={companyCountry}  />
                        ) : (
                            <p>This is not transcripts tab</p>
                        )}
                        </div>
                        <div className="tab-pane fade" id="nav-filings" role="tabpanel" aria-labelledby="nav-filings-tab">
                        {showTab === "filings" ? (
                            <CompanyFilings companyId={companyId} companyTicker={companyTicker} companyCountry={companyCountry}  />
                        ) : (
                            <p>This is not filings tab</p>
                        )}
                        </div>
                        <div className="tab-pane fade" id="nav-financials" role="tabpanel" aria-labelledby="nav-financials-tab">
                        {showTab === "financials" ? (
                            <CompanyFinancials companyId={companyId} companyTicker={companyTicker} companyCountry={companyCountry}  />
                        ) : (
                            <p>This is not financials tab</p>
                        )}
                        </div>
                        <div className="tab-pane fade" id="nav-releases" role="tabpanel" aria-labelledby="nav-releases-tab">
                        {showTab === "releases" ? (
                            <CompanyReleases companyId={companyId} companyTicker={companyTicker} companyCountry={companyCountry}  />
                        ) : (
                            <p>This is not releases tab</p>
                        )}
                        </div>
                        </div>

                        </div>
                        </div>
                        </Fragment>
                    )
                })
            ) :(
                <div className="row">
                <div className="col-lg-12">
                <div className="alert alert-danger" role="alert">
                Error: Cannot find company "{symbol}", please try again
                </div>
                </div>
                </div>
            )}
            </div>
            
            </div>
            </div>
        );
    }
}

export default CompanyMain;