import { Component, Fragment } from "react";
import ReactHtmlParser from 'react-html-parser';

class CompanyFinancials extends Component {
    constructor(props){
        super(props);

        this.state = {
            companyId: props.companyId,
            companyTicker: props.companyTicker,
            companyCountry: props.companyCountry,
            tabLoaded: false,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            data: []
        }
    }

    async componentDidMount() {
        try {                  
          const main_action_url = "https://www.insidearbitrage.com/cct-scripts/get_company_financials.php"; 
          const apiKey = this.state.apiKey;
          const postData = {
            company_id: this.state.companyId,
            company_ticker: this.state.companyTicker,
            company_country: this.state.companyCountry
          }
          const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Cct-Access': apiKey
            },
            body: JSON.stringify(postData)
          }         
          const response = await fetch(main_action_url, requestOptions);
          const ajaxResponse = await response.json();
          this.setState({ data: ajaxResponse });
          this.setState({tabLoaded: true})
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }
    
    render(){
        const{companyId, companyTicker, companyCountry, data, tabLoaded} = this.state;

        //console.log(companyCountry);

        return(
            <div className="mt-3">
            
            <div className="row" style={tabLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={tabLoaded ? {display: 'block'} : {display: 'none'}}>
            <div className="row">
            <div className="col-lg-12">
            {data.length !== 0 ? (
                 data.map((row) => {
                    let financials = row.financials;
                    if(financials === null || financials === ""){
                        financials = "";
                    }

                    if(financials !== ""){
                        return(
                            <div style={{ overflowX: 'auto' }} key="financials">
                            {ReactHtmlParser(financials)}
                            </div>
                        );
                    } else {
                        return(
                            <h4 key="financials">Sorry, no financials on record.</h4> 
                        );
                    }
                 })
            ) : (
                <h4>Sorry, no financials on record.</h4>
            )}
            </div>
            </div>
            </div>

            </div>
        )
    }
}

export default CompanyFinancials;