import { Fragment, useState, useEffect } from "react";
import { Outlet, Link } from "react-router-dom";
import AutosuggestComponent from "./autosuggest.component";

import SiteLogo from '../assets/img/logo.png';
 
const SiteHeader = () => {
    const [isSticky, setIsSticky] = useState(false);
  
    useEffect(() => {
      const handleScroll = () => {
        const header = document.getElementById('main-header');
        const sticky = header.offsetTop;
        const scrollUpElement = document.getElementById('scrollUp');
        const shouldSticky = window.pageYOffset > sticky;
  
        setIsSticky(shouldSticky);
  
        if (scrollUpElement) {
          scrollUpElement.style.display = shouldSticky ? 'block' : 'none';
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
        <Fragment>
                <div className="top-bar d-xxl-none d-xl-none d-lg-none d-md-block d-sm-block d-block pt-2 pb-2">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-12 d-flex justify-content-md-center justify-content-sm-center justify-content-center mb-2">
                            <div className="header-top-btn-mobile"><a href="https://www.insidearbitrage.com/contact-us/" target="_blank">Contact Us</a></div>
                            <div className="header-top-btn-mobile"><a href="https://www.insidearbitrage.com/about/" target="_blank">About Us</a></div>
                            <div className="header-top-btn-mobile"><a href="/get-notified">Get Notified!</a></div>
                            <div className="header-top-btn-mobile"><a href="/my-account">My Account</a></div>
                        </div>
                        <div className="col-md-12 d-flex justify-content-md-center justify-content-sm-center justify-content-center">
                            <form className="top-bar-search-form" action="/" method="get" role="search">
                            <div className="top-bar-search-form-container">
                                <AutosuggestComponent id="header-mobile-search" name="co" />
                                <button className="top-bar-search-button" type="submit" aria-label="Search"><i className="fas fa-search" />
                                </button>
                            </div>
                            </form>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div className="top-bar d-xxl-block d-xl-block d-lg-block d-md-none d-sm-none d-none">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-6 d-flex justify-content-start">
                            <div className="header-top-btn"><a href="https://www.insidearbitrage.com/contact-us/" target="_blank">Contact Us <i className="fa-solid fa-envelope" /></a></div>
                            <div className="header-top-btn"><a href="https://www.insidearbitrage.com/about/" target="_blank">About Us <i className="fa-solid fa-circle-info" /></a></div>
                        </div>
                        <div className="col-md-6 d-flex justify-content-end">
                            <form className="top-bar-search-form" action="/" method="get" role="search">
                            <div className="top-bar-search-form-container">
                                <AutosuggestComponent id="header-search" name="co" />
                                <button className="top-bar-search-button" type="submit" aria-label="Search"><i className="fas fa-search" />
                                </button>
                            </div>
                            </form>
                            <div className="header-top-btn"><a href="/get-notified">Get Notified! <i className="fa-solid fa-location-arrow" /></a></div>
                            <div className="header-top-btn"><a href="/my-account">My Account <i className="fa-regular fa-user" /></a></div>
                        </div>
                        </div>
                    </div>
                    </div>
                    <div id="main-header" className={isSticky ? "header-middle-area header-sticky" : "header-middle-area"}>
                    <div className="container">
                        <div className="row position-relative">
                        <div className="col-xl-3 col-8 me-0 pe-0" style={{"zIndex":"10"}}>
                            <div className="site-logo-wrap">
                            <div className="site-logo">
                                <a href="/"><img src={SiteLogo}alt="Logo" /></a>
                            </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-auto d-flex justify-content-end navbar-container">
                            <label htmlFor="navigation-menu-tm" id="navigation-toggle-menu"><span className="drop-icon"><i className="fa-solid fa-bars" /></span></label>
                            <nav id="navigation-menu">
                            <input type="checkbox" id="navigation-menu-tm" />
                            <ul className="navigation-menu-main-menu cf">
                                <li><a href="/get-notified">Get Notified!</a></li>
                                <li><a href="/prefs">Preferreds</a></li>
                                <li><a href="/debs">Debentures</a></li>
                                <li><a href="https://www.insidearbitrage.com/stock-buybacks/" target="_blank">Cannibals</a></li>
                            </ul>
                            </nav>
                        </div>
                        </div>
                    </div>
                    </div>
            <Outlet />
        </Fragment>
    )
}

export default SiteHeader;