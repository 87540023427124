import { Component, Fragment, Fragmenta } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { getQueryStringValue } from '../utils/general.utils';

class ArticleSummary extends Component {
    constructor(){
        super();

        this.state = {
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            data: [],
            pageLoaded: false
        }
    }

    async componentDidMount() {
        try {   
            const article_id = getQueryStringValue("id");             
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/get_article.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                article_id: article_id
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            this.setState({ data: ajaxResponse });
            this.setState({ pageLoaded: true })
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }


    render(){
        document.title = 'Conference Call Transcripts';
        const{pageLoaded, data} = this.state;

        return(
            <div id="page-content" className="mt-1 mb-5">
            <div className="container">

            <div className="row" style={pageLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={pageLoaded ? {display: 'block'} : {display: 'none'}}>
            {data.length !== 0 ? (
                data.map((row) => {
                    let title = row.title;
                    let article = row.article;

                    return(
                        <Fragment key={`article_summary`}>
                        <div className="row">
                        <div className="col-lg-12">
                        <h1 className="entry-title">{title}</h1>
                        </div>
                        </div>

                        <div className="row">
                        <div className="col-lg-12">
                        {ReactHtmlParser(article)}
                        </div>
                        </div>
                        </Fragment>
                    )
                })
            ) : (
                <div className="row">
                <div className="col-lg-12">
                <div className="alert alert-danger" role="alert">
                Error: Invalid URL or ID. Please try again.
                </div>
                </div>
                </div>
            )}            
            </div>

            </div>
            </div>
        );
    }
}

export default ArticleSummary;