import { Component, Fragment } from "react";
import ReactHtmlParser from 'react-html-parser';
import { getCookieValue } from "../utils/general.utils";
import AjaxLoaderImg from '../assets/img/ajax-loader-small.gif';

class ModalFormComponent extends Component {
    constructor(props){
        super(props)

        const form_type = props.formType;
        const company_id = props.companyId;
        let initialFormData = {};
        if (form_type === "notesForm") {
            initialFormData = {
                notes: "", 
                updated_company_id: ""
            };
        } else if(form_type === "sitesForm"){
            initialFormData = {
                site_name: "", 
                site_url: "",
                updated_company_id: ""
            };
        }

        this.state = {
            companyId: company_id,
            formType: form_type,
            showBtn: true,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            formLoaded: false,
            companyName: "",
            companyTicker: "",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            formData: initialFormData,
            sites: [],
            site_to_delete: ""
        }    
    }

    componentDidUpdate(prevProps) {
        if (prevProps.companyId !== this.props.companyId || prevProps.formType !== this.props.formType) {
            this.setState({
                companyId: this.props.companyId,
                formType: this.props.formType,
                formLoaded: false
            });

            this.loadForm(this.props.companyId, this.props.formType);
        }
    }

    loadForm = async(company_id, form_type) => {
        const cct_user_id = getCookieValue("cct_user_id");

        this.setState({
            showBtn: true,
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        });

        if(form_type === "notesForm"){
            try {                  
                const main_action_url = "https://www.insidearbitrage.com/cct-scripts/notifications.php"; 
                const apiKey = this.state.apiKey;
                const postData = {
                    user_id: cct_user_id,
                    company_id: company_id,
                    request_type: "get_notes"
                }
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Cct-Access': apiKey
                    },
                    body: JSON.stringify(postData)
                }         
                const response = await fetch(main_action_url, requestOptions);
                const ajaxResponse = await response.json();
                
                if (ajaxResponse.length > 0) {
                    const{company_id, company_ticker, company_name, notes} = ajaxResponse[0];

                    this.setState({
                        companyName: company_name,
                        companyTicker: company_ticker,
                        formData: {
                            notes: notes,
                            updated_company_id: company_id
                        }
                    })
                }

                this.setState({formLoaded: true})
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }

        if(form_type === "sitesForm"){
            try {                  
                const main_action_url = "https://www.insidearbitrage.com/cct-scripts/notifications.php"; 
                const apiKey = this.state.apiKey;
                const postData = {
                    user_id: cct_user_id,
                    company_id: company_id,
                    request_type: "get_sites"
                }
                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Cct-Access': apiKey
                    },
                    body: JSON.stringify(postData)
                }         
                const response = await fetch(main_action_url, requestOptions);
                const ajaxResponse = await response.json();
                
                if (ajaxResponse.length > 0) {
                    const{company_id, company_ticker, company_name, sites} = ajaxResponse[0];

                    this.setState({
                        companyName: company_name,
                        companyTicker: company_ticker,
                        sites: sites,
                        formData: {
                            updated_company_id: company_id
                        }
                    })
                }

                this.setState({formLoaded: true})
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
    }

    fetchSites = async() => {
        const cct_user_id = getCookieValue("cct_user_id");
        const company_id = this.state.companyId;
        try {                  
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                company_id: company_id,
                request_type: "get_sites"
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Cct-Access': apiKey
                },
                body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();
            
            if (ajaxResponse.length > 0) {
                const{sites} = ajaxResponse[0];

                this.setState({
                    sites: sites,
                })
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState((prevState) => ({
        formData: {
            ...prevState.formData,
            [name]: value,
        },
        }));
    };

    handleSubmit = async(e) => {
        e.preventDefault();

        const cct_user_id = getCookieValue("cct_user_id");
        const { formType } = this.state;

        this.setState({
            showBtn: false,
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        });

        if(formType === "notesForm"){
            const{ notes, updated_company_id } = this.state.formData;

            try {                
                const main_action_url = "https://www.insidearbitrage.com/cct-scripts/notifications.php"; 
                const apiKey = this.state.apiKey;
                const postData = {
                    user_id: cct_user_id,
                    notes: notes,
                    company_id: updated_company_id,
                    request_type: "update_notes"
                }
                const requestOptions = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'X-Cct-Access': apiKey
                  },
                  body: JSON.stringify(postData)
                }         
                const response = await fetch(main_action_url, requestOptions);
                const ajaxResponse = await response.json();      
                this.setState({showBtn: true});
                this.props.onSubmit(this.state.formData);          
              } catch (error) {
                console.error('Error fetching data:', error);
              }
        }

        if(formType === "sitesForm"){
            const{ site_name, site_url, updated_company_id } = this.state.formData;
            try {                
                const main_action_url = "https://www.insidearbitrage.com/cct-scripts/notifications.php"; 
                const apiKey = this.state.apiKey;
                const postData = {
                    user_id: cct_user_id,
                    site_name: site_name,
                    site_url: site_url,
                    company_id: updated_company_id,
                    request_type: "update_sites"
                }
                const requestOptions = {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      'X-Cct-Access': apiKey
                  },
                  body: JSON.stringify(postData)
                }         
                const response = await fetch(main_action_url, requestOptions);
                const ajaxResponse = await response.json();      
                
                if (ajaxResponse.length > 0) {
                    const { errorMsg, successMsg, site_update_status, sites } = ajaxResponse[0];
                    let site_actions = {};
                    if(errorMsg !== ""){
                        this.setState({
                            showBtn: true,
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: errorMsg,
                            successMsg: ""
                        })
                    } else {
                        if(successMsg !== "" && site_update_status === "updated"){
                            this.setState({
                                displayError: false,
                                displaySuccess: true,
                                errorMsg: "",
                                successMsg: successMsg,
                                showBtn: true,
                                formData:{
                                    site_name: "",
                                    site_url: "",
                                    updated_company_id: updated_company_id
                                }
                            });   
                            site_actions = {
                                updated_company_id: updated_company_id,
                                sites: sites
                            }   
                            //console.log(site_actions)
                            this.props.onSubmit(site_actions);   
                            this.fetchSites();
                        } else {
                            this.setState({
                                showBtn: true,
                                displayError: true,
                                displaySuccess: false,
                                errorMsg: "There is some error while processing your request. Please try again.",
                                successMsg: ""
                            });
                        }
                    }
                }
              } catch (error) {
                console.error('Error fetching data:', error);
              }
        }
    }

    deleteSite = async(site_id, company_id) => {
        if (this.state.deleting) {
            return;
        }

        const cct_user_id = getCookieValue('cct_user_id');

        this.setState({
            deleting: true, 
            site_to_delete: site_id
        });

        try {                
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                company_id: company_id,
                site_id: site_id,
                request_type: "delete_site"
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, site_delete_status, have_sites } = ajaxResponse[0];
                let site_actions = {};
                
                if(errorMsg !== ""){
                    this.setState({
                        displayError: false,
                        displaySuccess: false,
                        errorMsg: "",
                        successMsg: "",
                        deleting: false
                    });
                } else {
                    if(successMsg !== "" && site_delete_status === "site_deleted"){
                        this.setState({
                            displayError: false,
                            displaySuccess: false,
                            errorMsg: "",
                            successMsg: "",
                            deleting: false
                        });  
                        //site_actions[company_id] = have_sites;  
                        site_actions = {
                            updated_company_id: company_id,
                            sites: have_sites
                        }  
                        //console.log(site_actions)
                        this.props.onSubmit(site_actions);   
                        this.fetchSites();             
                        //this.setState({ deleting: false }) 
                    } else {
                        this.setState({
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: "",
                            deleting: false
                        });
                    }
                }
            }

            
          } catch (error) {
            console.error('Error fetching data:', error);
          }
    }

    render(){
        const{ companyId, showBtn, formType, formLoaded, companyName, companyTicker, displayError, errorMsg, displaySuccess, successMsg } = this.state;
        const { sites, site_to_delete, deleting } = this.state;
        const { handleChange, handleSubmit, deleteSite } = this;

        //console.log(this.formData.notes);

       if(formType === "notesForm"){
        return(
            <div className="mb-3">
                
            <div className="row" style={formLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={formLoaded ? {display: 'block'} : {display: 'none'}}>

            <div className="modal-product-info inner-text" style={{paddingRight: '25px'}}>
            <div className="row">
            <div className="col-lg-12">
            <h4 style={{paddingRight: '50px'}}>{companyName} ({companyTicker})</h4>
            </div>
            </div>
            
            <hr />

            <div className="row mt-3">
            <div className="col-lg-12">
            <div className="alert alert-success mt-3 mb-0" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>
            <div className="alert alert-danger mt-3 mb-0" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>
            </div>
            </div>

            <form onSubmit={handleSubmit}>
            <div className="row mt-2">
            <div className="col-lg-12">
            <div className="form-group">
            <label htmlFor="notes"><b>Notes</b></label>
            <textarea className="form-control" id="notes" name="notes" rows="3" value={this.state.formData.notes !== null ? this.state.formData.notes || '' : ''} onChange={handleChange}></textarea>
            </div>
            </div>
            </div>

            <div className="form-group mt-3">
            <div style={showBtn ? {display: 'none'} : {display: 'block'}}>
            <div className="d-flex" >
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>

            <input type="hidden" name="updated_company_id" id="updated_company_id" value={this.state.formData.updated_company_id !== null ? this.state.formData.updated_company_id || '' : ''} onChange={handleChange} />
            <button type="submit" className="theme-btn-1 btn btn-effect-1 mt-3" style={showBtn ? {display: 'block'} : {display: 'none'}}>Update Notes</button>
            </div>
            </form>

            </div>

            </div>

            </div>
        )
       }

       if(formType === "sitesForm"){
        return(
            <div className="mb-3">
            
            <div className="row" style={formLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="col-lg-12">
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>
            </div>

            <div style={formLoaded ? {display: 'block'} : {display: 'none'}}>

            <div className="modal-product-info inner-text" style={{paddingRight: '25px'}}>
            <div className="row">
            <div className="col-lg-12">
            <h4 style={{paddingRight: '50px'}}>{companyName} ({companyTicker})</h4>
            </div>
            </div>
            
            <hr />

            <div className="row mt-3">
            <div className="col-lg-12">
            <div className="alert alert-success mb-2" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(successMsg)}</div>
            <div className="alert alert-danger mb-2" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>{ReactHtmlParser(errorMsg)}</div>
            </div>
            </div>

            <form onSubmit={handleSubmit}>
            <div className="row">
            <div className="col-lg-12">
            <div className="form-group">
            <label htmlFor="site_name"><b>Name it</b></label>
            <input type="text" className="form-control" id="site_name" name="site_name"  onChange={handleChange} value={this.state.formData.site_name !== null ? this.state.formData.site_name || '' : ''} />
            <small id="site_nameHelp" className="form-text text-muted">(e.g. Chairman's Letter, monthly sales etc)</small>
            </div>
            </div>
            </div>

            <div className="row">
            <div className="col-lg-12">
            <div className="form-group">
            <label htmlFor="site_url"><b>URL</b></label>
            <input type="text" className="form-control" id="site_url" name="site_url"  onChange={handleChange} value={this.state.formData.site_url !== null ? this.state.formData.site_url || '' : ''} />
            <small id="site_urlHelp" className="form-text text-muted">(You will be notified when there are changes to this page)</small>
            </div>
            </div>
            </div>

            <div className="form-group mt-3 d-flex justify-content-center">
            <div style={showBtn ? {display: 'none'} : {display: 'block'}}>
            <div className="d-flex" >
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>

            <input type="hidden" name="updated_company_id" id="updated_company_id" value={this.state.formData.updated_company_id !== null ? this.state.formData.updated_company_id || '' : ''} onChange={handleChange} />
            <button type="submit" className="theme-btn-1 btn btn-effect-1 mt-3" style={showBtn ? {display: 'block'} : {display: 'none'}}>Add It</button>
            </div>
            </form>

            <div className="row mt-3">
            <div className="col-lg-12 d-flex justify-content-center">
            <table className='portfolio-data-table' width="100%" style={{tableLayout: "fixed"}}>
            <thead>
            <tr>
            <th width="30%">Title</th>
            <th width="60%">URL</th>
            <th width="10%">Delete</th>
            </tr>
            </thead>

            <tbody>
            {sites.length !== 0 ? (
                sites.map((site, siteIndex) => {
                    let site_id = site.site_id;
                    let site_title = site.site_name;
                    let site_url = site.site_url;

                    return(
                        <tr key={`site-item-${siteIndex}`}>
                        <td>{site_title}</td>
                        <td style={{whiteSpace:"nowrap", overflow: "hidden", textOverflow: "ellipsis"}}><a href={site_url} target="_blank" title={site_url} rel="nofollow">{site_url}</a></td>
                        <td valign="middle" align="center"><img src={AjaxLoaderImg} style={site_to_delete === site_id && deleting ? {display:'block'} : {display:'none'}} /> {<button className="delete-btn-portfolio" style={site_to_delete === site_id && deleting ? {display:'none'} : {display:'block'}} onClick={() => deleteSite(site_id, companyId)}><i className="fa-solid fa-trash-can" aria-hidden="true"></i></button>}</td>
                        </tr>
                    )
                })
            ) : (
                    <tr>
                    <td colSpan={3}><center>No data found.</center></td>
                    </tr>
            )}
            </tbody>
            </table>
            </div>
            </div>

            </div>
            
            </div>

            </div>
        )
       }        
    }
}

export default ModalFormComponent;