import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import SiteHeader from './components/site-header.component';
import SiteFooter from './components/site-footer.component';
import Home from './routes/home.component';
import DebenturesMain from './routes/debentures.component';
import PreferredsMain from './routes/preferreds.component';
import ArticleSummary from './routes/summary.component';
import MyAccount from './routes/myAccount.component';
import Subscribe from './routes/subscribe.component';
import AccountActivation from './routes/activateAccount.component';
import ForgotPassword from './routes/forgotPassword.component';
import MyNotifications from './routes/notifications.component';
import MoneyManagers from './routes/moneyManagers.component';
import SiteUpdates from './routes/siteUpdates.component';
import MySearches from './routes/searches.component';
import ChangePassword from './routes/changePassword.component';
import EditAccount from './routes/editAccount.component';
import UnsubscribeUser from './routes/unsubscribe.component';
import Logout from './routes/logout.component';
import './App.css';

const App = () => {
  return (
    <Fragment>
      <SiteHeader />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/debs" element={<DebenturesMain />} />
        <Route path="/prefs" element={<PreferredsMain />} />
        <Route path="/summary" element={<ArticleSummary />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/get-notified" element={<Subscribe />} />
        <Route path="/activate" element={<AccountActivation />} />
        <Route path="/forgot" element={<ForgotPassword />} />
        <Route path="/my-account/notifications" element={<MyNotifications />} />
        <Route path="/my-account/managers" element={<MoneyManagers />} />
        <Route path="/my-account/websites" element={<SiteUpdates />} />
        <Route path="/my-account/searches" element={<MySearches />} />
        <Route path="/my-account/change-password" element={<ChangePassword />} />
        <Route path="/my-account/edit-account" element={<EditAccount />} />
        <Route path="/my-account/logout" element={<Logout />} />
        <Route path="/unsubscribe" element={<UnsubscribeUser />} />
      </Routes>
      <SiteFooter />
    </Fragment>
  );
}

export default App;
