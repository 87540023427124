import { Component, Fragment } from 'react';

class UserLogin extends Component {
    constructor(){
        super();

        this.state = {
            showBtn: true,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: "",
            formData: {
                username: "",
                password: "",
                rememberMe: false
            }
        }
    }

    handleSubmit = async(e) => {
        e.preventDefault();
        
        const { username, password, rememberMe } = this.state.formData;
        
        /*console.log("Username:", username);
        console.log("Password:", password);
        console.log("Remember Me:", rememberMe);*/

        this.setState({
            showBtn: false,
            displayError: false,
            displaySuccess: false,
            errorMsg: "",
            successMsg: ""
        });

        try {                
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/user_login.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                username: username,
                password: password
            }
            const requestOptions = {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
                  'X-Cct-Access': apiKey
              },
              body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, login_status, user_id, username, password, email } = ajaxResponse[0];

                //console.log("db_error", errorMsg);

                if(errorMsg !== ""){
                    this.setState({
                        showBtn: true,
                        displayError: true,
                        displaySuccess: false,
                        errorMsg: errorMsg,
                        successMsg: ""
                    });
                } else {
                    if(successMsg !== "" && login_status === "logged_in"){
                        this.setState({
                            displayError: false,
                            displaySuccess: true,
                            errorMsg: "",
                            successMsg: successMsg
                        });

                        /*console.log("Username:", username);
                        console.log("Password:", password);
                        console.log("Remember Me:", rememberMe);
                        console.log("login status:", login_status);*/                       

                        let expirationDate = "";

                        if(rememberMe){
                            expirationDate = new Date();
                            expirationDate.setMonth(expirationDate.getMonth() + 1);
                        } else {
                            expirationDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
                        }

                        document.cookie = `cct_username=${username}; expires=${expirationDate.toUTCString()}; path=/`;
                        document.cookie = `cct_password=${password}; expires=${expirationDate.toUTCString()}; path=/`;
                        document.cookie = `cct_user_email=${email}; expires=${expirationDate.toUTCString()}; path=/`;
                        document.cookie = `cct_user_id=${user_id}; expires=${expirationDate.toUTCString()}; path=/`;

                        const redirect_url = `${window.location.origin}/my-account/notifications`;
                        window.location.href = redirect_url;
                    } else {
                        this.setState({
                            showBtn: true,
                            displayError: true,
                            displaySuccess: false,
                            errorMsg: "There is some error while processing your request. Please try again.",
                            successMsg: ""
                        });
                    }
                }
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }


    }

    handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const fieldValue = type === "checkbox" ? checked : value;
        this.setState(prevState => ({
            formData: {
                ...prevState.formData,
                [name]: fieldValue
            }
        }));
    }

    render(){
        document.title = 'My Account - Conference Call Transcripts';
        const { showBtn, displayError, errorMsg, displaySuccess, successMsg } = this.state;
        const { handleSubmit, handleChange } = this;

        return(
            <div id="page-content" className="mt-5 mb-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-4 offset-md-4 col-12">         
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='login-page-title'>
                                    <h4 className='text-center'>Login</h4>
                                </div>
                            </div>
                        </div>

                        <div className="alert alert-success mt-3 mb-0" role="alert" style={displaySuccess ? {display: 'block'} : {display: 'none'}}>
                            {successMsg}
                        </div>

                        <div className="alert alert-danger mt-3 mb-0" role="alert" style={displayError ? {display: 'block'} : {display: 'none'}}>
                            {errorMsg}
                        </div>

                        <div className='row'>
                            <div className='col-lg-12'>
                                <form onSubmit={handleSubmit}>
                                    <div className="form-group mt-3">
                                        <label htmlFor="username">Username</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="username"
                                            id="username"
                                            onChange={handleChange}
                                            value={this.state.formData.username}
                                        />
                                    </div>

                                    <div className="form-group mt-3">
                                        <label htmlFor="password">Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            id="password"
                                            onChange={handleChange}
                                            value={this.state.formData.password}
                                        />
                                    </div>

                                    <div className="form-check mt-3">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="rememberMe"
                                            name="rememberMe"
                                            onChange={handleChange}
                                            checked={this.state.formData.rememberMe}
                                        />
                                        <label className="form-check-label" htmlFor="rememberMe">Remember Me</label>
                                    </div>

                                    <div className="form-group mt-3">
                                    <a href='/forgot'>Lost your Password?</a>
                                    </div>

                                    <div className="form-group mt-3">
                                        <div style={showBtn ? {display: 'none'} : {display: 'block'}}>
                                            <div className="d-flex" >
                                                <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </div>
                                        </div>

                                        <button
                                            type="submit"
                                            className="theme-btn-1 btn btn-effect-1 mt-3"
                                            style={showBtn ? {display: 'block'} : {display: 'none'}}
                                        >
                                            Login
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        )
    }
}

export default UserLogin;
