export const formatDate = (date_to_format, date_format) => {
    if(date_format === undefined){
      date_format = "n/d/y";
    }
    const dateObject = new Date(date_to_format);
    let options = "";

    if(date_format == "n/d/y"){
      options = { year: '2-digit', month: 'numeric', day: 'numeric' };
    }

    if(date_format == "l, F d, Y"){
      options = { weekday: 'long', month: 'long', day: '2-digit', year: 'numeric' };
    }

    if(date_format == "M j, Y"){
      options = { year: 'numeric', month: 'short', day: 'numeric' };
    }

    if(date_format == "F d, Y"){
      options = { year: 'numeric', month: 'long', day: '2-digit' };
    }

    return dateObject.toLocaleDateString(undefined, options);
}

export const sortTable = (dataToSort, column, dataType, sortOrder) => {
    const sortedData = [...dataToSort].sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];

        if(dataType == "number"){
            return sortOrder === 'asc' ? valueA - valueB : valueB - valueA;
          } else if(dataType == "date"){
            return sortOrder === 'asc' ? new Date(valueA) - new Date(valueB) : new Date(valueB) - new Date(valueA);
          } else {
            const stringA = String(valueA).toLowerCase();
            const stringB = String(valueB).toLowerCase();
    
            return sortOrder === 'asc' ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
          }
    });

    return sortedData;
}

export const getQueryStringValue = (parameter_to_search) => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);
  let param_value = searchParams.get(parameter_to_search);

  if (param_value === null || param_value === undefined) {
    param_value = "";
  }

  return param_value;
}

export const formatDBTime = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':');
  const date = new Date();
  date.setHours(hours, minutes, seconds);
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true, // Use 12-hour clock
  };
  const formattedTime = date.toLocaleTimeString([], options);
  return formattedTime;
}

export const decodeHtmlEntities = (html) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;
}

export const getCookieValue = (name) => {
  const cookies = document.cookie.split(';').map(cookie => cookie.trim().split('='));
  const cookie = cookies.find(([cookieName]) => cookieName === name);
  return cookie ? cookie[1] : '';
}

export const removeCookie = (cookieName) => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const updateCookie = (cookieName, cookieValue) => {
  document.cookie = `${cookieName}=${cookieValue}; path=/`; 
};