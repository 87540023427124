import { Component, Fragment } from "react";
import { getCookieValue } from "../utils/general.utils";

class MyAccountMenu extends Component {
    constructor(props){
        super();

        this.state = {
            page_name: props.page_name
        }
    }

    render(){
        const{page_name} = this.state;
        
        const cct_username = getCookieValue("cct_username");
        return(
            <Fragment>
            <div className="row">
            <div className="col-lg-12">
            <h1 className="entry-title" style={{ textTransform: "none" }}>Logged in as {cct_username}</h1>
            </div>
            </div>
            

            <div className="row mb-5">
            <div className="col-lg-12">
            <ul className="nav nav-tabs">
            <li className="nav-item"><a className={page_name === "companies" ? "nav-link active" : "nav-link"} aria-current="page" href="/my-account/notifications">Companies</a></li>
            <li className="nav-item"><a className={page_name === "managers" ? "nav-link active" : "nav-link"} aria-current="page" href="/my-account/managers">Money Managers</a></li>
            <li className="nav-item"><a className={page_name === "siteUpdates" ? "nav-link active" : "nav-link"} aria-current="page" href="/my-account/websites">Site Updates</a></li>
            <li className="nav-item"><a className={page_name === "searches" ? "nav-link active" : "nav-link"} aria-current="page" href="/my-account/searches">Searches</a></li>
            <li className="nav-item"><a className={page_name === "edit-account" ? "nav-link active" : "nav-link"} aria-current="page" href="/my-account/edit-account">Edit Account</a></li>
            <li className="nav-item"><a className={page_name === "change-password" ? "nav-link active" : "nav-link"} aria-current="page" href="/my-account/change-password">Change Password</a></li>
            <li className="nav-item"><a className={page_name === "logout" ? "nav-link active" : "nav-link"} aria-current="page" href="/my-account/logout">Logout</a></li>
            </ul>
            </div>
            </div>
            </Fragment>
        );
    }
}

export default MyAccountMenu;