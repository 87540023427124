import { Component } from 'react';
import HomeMain from '../components/home-main.component';
import CompanyMain from '../components/company.component';
import IndividualMoneyManager from '../components/individualManager.component';

import { getQueryStringValue } from '../utils/general.utils';

class Home extends Component {
  render(){
    const co = getQueryStringValue("co");

    if(co !== ""){
      if(co.endsWith(".MGR")){
        return(<IndividualMoneyManager symbol={co} />)
      } else {
        return(<CompanyMain symbol={co} />)
      }
    } else {
      return(<HomeMain />)
    }
  }
}

export default Home;