import { Component } from 'react';
import UserLogin from '../components/login.component';

import { getCookieValue } from '../utils/general.utils';

class MyAccount extends Component {
    render(){
        const cct_username = getCookieValue('cct_username');
        const cct_password = getCookieValue('cct_password');

        if(cct_username !== "" && cct_password !== ""){
            const redirect_url = `${window.location.origin}/my-account/notifications`;
            window.location.href = redirect_url;
        } else {
            return(<UserLogin />)
        }
        
    }
}

export default MyAccount;