import { Component } from "react";
import { getCookieValue } from "../utils/general.utils";
import AjaxLoaderImg from '../assets/img/ajax-loader-small.gif';

class AddNotificationsBtn extends Component {
    constructor(props){
        super(props);

        this.state = {
            companyId: props.companyId,
            companyTicker: props.companyTicker,
            companyCountry: props.companyCountry,
            following: props.following,
            apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
            processing: {},
            adding: {}
        }
    }

    handleFollowing = async(event, action_to_process) => {
        const company_id = event.target.getAttribute('data-company-id');
        const company_ticker = event.target.getAttribute('data-company-ticker');
        const cct_user_id = getCookieValue('cct_user_id');

        if(cct_user_id === ""){
            const redirect_url = `${window.location.origin}/my-account/`;
            window.location.href = redirect_url;
        } else {
            let request_type = "";
            if(action_to_process === "following"){
                request_type = "add";
            } else {
                request_type = "delete";
            }       

            //alert(request_type);

            this.setState(prevState => ({
            processing: {
                ...prevState.processing,
                [company_id]: true,
            },
            }));

            try {
            const main_action_url = "https://www.insidearbitrage.com/cct-scripts/notifications.php"; 
            const apiKey = this.state.apiKey;
            const postData = {
                user_id: cct_user_id,
                company_id: company_id,
                company_symbol: company_ticker,
                request_type: request_type
            }
            const requestOptions = {
                method: 'POST',
                headers: {
                'Content-Type': 'application/json',
                'X-Cct-Access': apiKey
                },
                body: JSON.stringify(postData)
            }         
            const response = await fetch(main_action_url, requestOptions);
            const ajaxResponse = await response.json();

            if (ajaxResponse.length > 0) {
                const { errorMsg, successMsg, notification_status } = ajaxResponse[0];

                if(errorMsg !== ""){
                    alert(errorMsg);
                } else {
                    if(successMsg !== ""){
                        this.setState(prevState => ({
                            adding: {
                            ...prevState.adding,
                            [company_id]: action_to_process,
                            },
                        }));
                    }
                }
            }
        
            this.setState(prevState => ({
                processing: {
                ...prevState.processing,
                [company_id]: false,
                },
            }));
        
            } catch (error) {
            console.error('Error fetching data:', error);
            this.setState(prevState => ({
                processing: {
                ...prevState.processing,
                [company_id]: false,
                },
            }));
            }
        }
    }
    
    render(){
        const{companyId, companyTicker, companyCountry, processing, adding} = this.state;
        const following = adding && adding[companyId] ? adding[companyId] : this.state.following;
        const{handleFollowing} = this;

        /*if(adding && adding[companyId]){
            following = adding[companyId];
        }*/

        return(
            <div>
            <img src={AjaxLoaderImg} style={processing[companyId] ? {display:'block'} : {display:'none'}} />
            {following === "following" ? (
                <span className="stock-follow-button following" data-ref="stock-follow-button" data-company-id={companyId} data-company-ticker={companyTicker} onClick={(event) => handleFollowing(event, "not_following")} style={processing[companyId] ? {display:'none'} : {display:'block'}}>
                <i className="fa-solid fa-star" data-ref="stock-follow-button"></i> In Notifications
                </span>
            ) : (
                <span className="stock-follow-button" data-ref="stock-follow-button" data-company-id={companyId} data-company-ticker={companyTicker} onClick={(event) => handleFollowing(event, "following")} style={processing[companyId] ? {display:'none'} : {display:'block'}}>
                <i className="fa-regular fa-star" data-ref="stock-follow-button"></i> Add to Notifications
                </span>
            )}
            </div>
        )
    }
}

export default AddNotificationsBtn;