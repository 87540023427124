import { Fragment } from "react";
import { Outlet, Link } from "react-router-dom";

const scrollToTop = () => {
    const rootElement = document.documentElement;

    // Scroll to top logic
    rootElement.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

const SiteFooter = () => {
    const currentYear = new Date().getFullYear();
    return(
    <Fragment>
        <Outlet />
        <div className="call-to-action-6 before-bg-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="call-to-action-inner call-to-action-inner-6 ltn__secondary-bg position-relative text-center---">
                  <div className="coll-to-info text-color-white">
                    <h1>Looking for more premium content?</h1>
                    <p>We have created a comprehensive group of tools that allows you to estimate the intrinsic value of investments.</p>
                  </div>
                  <div className="btn-wrapper">
                    <a className="btn btn-effect-3 btn-white" href="https://www.insidearbitrage.com/subscription/">Subscribe <i className="fa-solid fa-arrow-right" /></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top-area" />
        <div className="container-fluid footer-bottom-area">
          <div className="row">
            <div className="col-md-6 col-12">
              <div className="copyright-design text-lg-start text-center">
                © 2020 - {currentYear}, ConferenceCallTranscripts.org
              </div>
            </div>
            <div className="col-md-6 col-12 align-self-center">
              <div className="copyright-menu d-flex justify-content-lg-end justify-content-center">
                <ul>
                  <li><a href="https://www.insidearbitrage.com/terms-privacy-policy/">Terms &amp; Conditions</a></li>
                  <li><a href="https://www.insidearbitrage.com/terms-privacy-policy/">Privacy &amp; Policy</a></li> 
                </ul>
              </div>
            </div>
          </div>
        </div>
        <button id="scrollUp" style={{position: 'fixed', zIndex: 2147483647, display: 'none'}} onClick={scrollToTop}><i className="fa fa-angle-up" /></button>
    </Fragment>
    )
}

export default SiteFooter;