import { Component, Fragment } from 'react';
import ReactHtmlParser from 'react-html-parser';
import AutosuggestComponent from './autosuggest.component';

class HomeMain extends Component {
  constructor(){
    super();

    this.state = {
      apiKey: "164be28c-8834-11ee-9c97-3cecefd5634e",
      newsLoaded: false,
      newsData: []
    }
  }

  async componentDidMount() {
    try {                  
      const main_action_url = "https://www.insidearbitrage.com/cct-scripts/home-news.php"; 
      const apiKey = this.state.apiKey;
      const requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-Cct-Access': apiKey
        }
      }         
      const response = await fetch(main_action_url, requestOptions);
      const ajaxResponse = await response.json();
      this.setState({ newsData: ajaxResponse });
      this.setState({ newsLoaded: true })
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  render(){
    document.title = 'Conference Call Transcripts';
    const {newsLoaded, newsData} = this.state;

    return(
        <div className="mt-1 mb-5">
        <div className="container">
          
          <div className="row">
            <div className="col-lg-12">
              <div className="homepage-search-area d-flex justify-content-center">
                <AutosuggestComponent id="homepage-search" />
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-12">
            <h1 className="common_title text-center">Latest News</h1>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
            <div className="ltn__border bg-white box-shadow-1 p-5">
            <div className="col-lg-12" style={newsLoaded ? {display: 'none'} : {display: 'block'}}>
            <div className="d-flex justify-content-center">
            <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
            </div>
            </div>
            </div>

            <div className="col-lg-12" style={newsLoaded ? {display: 'block'} : {display: 'none'}}>
              {newsData.map((newsRow) => {
                let news_headline = newsRow.headline;
                let news_article = newsRow.article;
                let news_url = newsRow.news_url;

                return(
                  <Fragment key="home-news">
                  <div className='row'>
                  <div className='col-lg-12'>
                  <h4 className='text-center'>{news_headline}</h4>
                  </div>
                  </div>
                  
                  <div className='row'>
                  <div className='col-lg-12 home-page-news'>
                  <p className='text-center'>{ReactHtmlParser(news_article)}</p>
                  <p className='text-center'><b>The above information was disclosed in a filing to the SEC. To see the filing, <a href={news_url} target='_blank' rel='nofollow'>click here</a>.</b></p>
                  </div>
                  </div>
                  </Fragment>
                )
              })}
            </div>
            </div>
            </div>
          </div>

          <div id='page-content' className='row mt-5'>
          <div className='col-lg-12'>
          <h4 className='text-center mb-0'>A service provided by <a href='https://www.insidearbitrage.com' target='_blank'><u>InsideArbitrage.com</u></a></h4>
          </div>
          </div>

          <div id='page-content' className='row mt-3'>
          <div className='col-lg-12'>
          <h4 className='text-center mb-0'>A few InsideArbitrage articles</h4>
          </div>
          </div>

          <div id='page-content' className="row mt-4">
            {/* ltn__product-item */}
            <div className="col-xl-4 col-sm-6 col-12 mb-5">
              <div className="d-flex flex-column ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                <div className="product-img">
                  <a href="product-details.html">
                    <img
                      src="https://www.insidearbitrage.com/wp-content/uploads/2023/09/IovanceInsiderPurchase.png"
                      alt="#"
                    />
                  </a>
                </div>
                <div className="product-info">
                  <h3 className="product-title">
                    <a
                      href="https://www.insidearbitrage.com/2023/09/famous-biotech-billionaire-purchases-26-5-million-worth-of-iova-insider-weekends/"
                      target="_blank"
                    >
                      Famous Biotech Billionaire Purchases $26.5 Million Worth of IOVA –
                      Insider Weekends
                    </a>
                  </h3>
                  <div className="product-img-location">
                    <p>
                      Key Insights Famous biotechnology investor Wayne P. Rothbaum
                      purchased $26.5 million worth of a clinical-stage biotechnology
                      company, Iovance Biotherapeutics Inc. (IOVA). Mr. Rothbaum is well
                    </p>
                  </div>
                  <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                    <li>
                      <b>
                        <a
                          href="https://www.insidearbitrage.com/2023/09/famous-biotech-billionaire-purchases-26-5-million-worth-of-iova-insider-weekends/"
                          target="_blank"
                        >
                          READ MORE
                        </a>
                      </b>
                    </li>
                  </ul>
                </div>
                <div className="product-info-bottom mt-auto">September 17, 2023</div>
              </div>
            </div>
            {/* ltn__product-item */}
            <div className="col-xl-4 col-sm-6 col-12 mb-5">
              <div className="d-flex flex-column ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                <div className="product-img">
                  <a
                    href="https://www.insidearbitrage.com/2023/10/a-garp-screen-and-the-30-stocks-it-pulled-up/"
                    target="_blank"
                  >
                    <img
                      src="https://www.insidearbitrage.com/wp-content/uploads/2023/10/New-IA-Site-Homeslider-Template-2023-10-17T234602.698.png"
                      alt="#"
                    />
                  </a>
                </div>
                <div className="product-info">
                  <h3 className="product-title">
                    <a
                      href="https://www.insidearbitrage.com/2023/10/a-garp-screen-and-the-30-stocks-it-pulled-up/"
                      target="_blank"
                    >
                      A GARP Screen and the 30 Stocks it Pulled Up
                    </a>
                  </h3>
                  <div className="product-img-location">
                    <p>
                      I was reflecting on the performance of my portfolio during the last
                      several years and realized that beyond opportunistic trades both on
                      the long and
                    </p>
                  </div>
                  <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                    <li>
                      <b>
                        <a
                          href="https://www.insidearbitrage.com/2023/10/a-garp-screen-and-the-30-stocks-it-pulled-up/"
                          target="_blank"
                        >
                          READ MORE
                        </a>
                      </b>
                    </li>
                  </ul>
                </div>
                <div className="product-info-bottom mt-auto">October 17, 2023</div>
              </div>
            </div>
            {/* ltn__product-item */}
            <div className="col-xl-4 col-sm-6 col-12 mb-5">
              <div className="d-flex flex-column ltn__product-item ltn__product-item-4 ltn__product-item-5 text-center---">
                <div className="product-img">
                  <a
                    href="https://www.insidearbitrage.com/2023/11/water-desalination-and-the-dune-express-insidearbitrage-mid-month-update/"
                    target="_blank"
                  >
                    <img
                      src="https://www.insidearbitrage.com/wp-content/uploads/2023/11/New-IA-Site-Homeslider-Template-2023-11-18T152406.948.png"
                      alt="#"
                    />
                  </a>
                </div>
                <div className="product-info">
                  <h2 className="product-title">
                    <a
                      href="https://www.insidearbitrage.com/2023/11/water-desalination-and-the-dune-express-insidearbitrage-mid-month-update/"
                      target="_blank"
                    >
                      InsideArbitrage November 2023 Mid-Month Update – Water Desalination
                      and The Dune Express
                    </a>
                  </h2>
                  <div className="product-img-location">
                    <p>
                      This mid-month update is a couple of days late because I was in
                      Dallas, Texas attending the IDEAS Conference where nearly 60
                      companies were presenting.
                    </p>
                  </div>
                  <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                    <li>
                      <b>
                        <a
                          href="https://www.insidearbitrage.com/2023/11/water-desalination-and-the-dune-express-insidearbitrage-mid-month-update/"
                          target="_blank"
                        >
                          READ MORE
                        </a>
                      </b>
                    </li>
                  </ul>
                </div>
                <div className="product-info-bottom mt-auto">November 17, 2023</div>
              </div>
            </div>
            {/*  */}
          </div>


        </div>
      </div>
    )
  }
}

export default HomeMain;